import React, { useState, useRef, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'

import { NewsProps } from './types'
import { useApp } from '@/core/contexts/app'
import { useMobile } from '@/core/hooks'

const News: React.FunctionComponent<NewsProps> = ({ title, news = [] }) => {
  const app = useApp()
  const isMobile = useMobile(app.headers['user-agent'])

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return (
        '<span ref={paginationRef} class="' +
        className +
        '"><div class="number">' +
        (index + 1) +
        '</div><div class="dot"></div></span>'
      )
    },
  }

  return (
    <div className="news">
      <Container size="extended">
        {isMobile && (
          <Row>
            <div className="news-title">{title}</div>
          </Row>
        )}
        <Row>
          <Column>
            <Swiper
              pagination={pagination}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[Pagination, Navigation]}
              className="news-swiper"
            >
              <div className="swiper-navigation">
                <div className="swiper-button-prev swiper-button" />
                <div className="swiper-button-next swiper-button" />
              </div>
              {news.map((item, index) => (
                <SwiperSlide key={index}>
                  <Row xs={{ align: 'center' }}>
                    <Column
                      xs={{ size: 12 }}
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                      lg={{ size: 6 }}
                      xl={{ size: 6 }}
                    >
                      <figure>
                        <Image {...item.thumbnail} alt={item.title} />
                      </figure>
                    </Column>
                    <Column
                      xs={{ size: 12 }}
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                      lg={{ size: 6 }}
                      xl={{ size: 6 }}
                    >
                      <div className="detail">
                        <div className="title">{item.title}</div>
                        {item.desc && (
                          <div
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                          />
                        )}
                      </div>
                    </Column>
                  </Row>
                </SwiperSlide>
              ))}
            </Swiper>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default News
